import api from "./api";

const URL_PREFIX = '/rizz/';

class RizzService {
  getRizzLine(category) {
    let url = URL_PREFIX + "getRizzLine";
    if (category) {
      url += "?category=" + encodeURIComponent(category);
    }
    return api.get(url);
  }
}

const rizzServiceInstance = new RizzService();

export default rizzServiceInstance;