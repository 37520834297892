import React, { useState, useEffect } from "react";
import NewsletterService from "services/newsletter.service";
import rizzService from "services/rizz.service";

// components

import Navbar from "components/Navbars/AuthNavbar.js";

export default function Landing() {

  const [success, setSuccess] = useState(false);
  const [rizz, setRizz] = useState("Do you know why it doesn’t matter if there’s gravity or not? Because I’d still fall for you.");

  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const getRizzLine = async (category) => {
    rizzService.getRizzLine(category)
      .then(response => {
        setRizz(response.data.rizz);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    getRizzLine();
  }, []);

  const handleSub = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Access the input value by name
    const email = e.target.elements['email'].value;
    NewsletterService.newSub(email)
      .then(response => {
        const message = response.data.message;
        setSuccess(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleCopyText = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(rizz);
        showTooltip();
      } catch (err) {
        console.error('Failed to copy quote: ', err);
      }
    } else {
      console.log("Clipboard API not supported");
    }
  }

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 flex content-center items-center justify-center min-h-screen xl:min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-stone-50 flex items-center justify-center"
          >
          </div>

          <div className="container relative mx-auto h-full flex justify-center items-center">
            <div className="w-full px-4 pt-16 ml-auto mr-auto text-center h-full flex flex-col items-center justify-center">
              <div className="relative inline-block">
                <h1
                  onMouseLeave={hideTooltip}
                  onFocus={showTooltip}
                  onBlur={hideTooltip}
                  onClick={handleCopyText}
                  className="font-semibold text-5xl hover:text-blueGray-500 cursor-pointer"
                  tabIndex="0"
                >
                  {`"${rizz}"`}
                </h1>
                <div
                  id="tooltip-top"
                  role="tooltip"
                  className={`absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700 ${isTooltipVisible ? 'visible opacity-100' : 'invisible opacity-0'}`}
                  style={{ bottom: '100%', left: '50%', transform: 'translateX(-50%)' }}
                >
                  Rizz copied to clipboard
                  <div
                    className="tooltip-arrow absolute border-gray-900 left-1/2 transform -translate-x-1/2"
                    data-popper-arrow
                  ></div>
                </div>
              </div>
              <button
                className="bg-transparent hover:shadow-lg font-medium h-12 w-12 items-center justify-center align-center rounded-full outline-none focus:outline-none m-4"
                type="button"
                onClick={() => getRizzLine()}
              >
                <i className="fas fa-dice text-3xl"></i>
              </button>
              <div className="flex flex-col md:flex-row  items-center justify-center py-10">
                <button type="button" onClick={() => getRizzLine('funny')} className="text-white w-150-px m-2 bg-stone-800 hover:bg-black hover:shadow-lg font-medium rounded-lg text-lg pr-4 pl-3 pt-2 pb-2.5 text-center inline-flex items-center justify-center">
                  😂 Funny
                </button>
                <button type="button" onClick={() => getRizzLine('spicy')} className="text-white w-150-px m-2 bg-stone-800 hover:bg-black hover:shadow-lg font-medium rounded-lg text-lg pr-4 pl-3 pt-2 pb-2.5 text-center inline-flex items-center justify-center">
                  🌶 Spicy
                </button>
                <button type="button" onClick={() => getRizzLine('classic')} className="text-white w-150-px m-2 bg-stone-800 hover:bg-black hover:shadow-lg font-medium rounded-lg text-lg pr-4 pl-3 pt-2 pb-2.5 text-center inline-flex items-center justify-center">
                  💎 Classic
                </button>
                <button type="button" onClick={() => getRizzLine('sweet')} className="text-white w-150-px m-2 bg-stone-800 hover:bg-black hover:shadow-lg font-medium rounded-lg text-lg pr-4 pl-3 pt-2 pb-2.5 text-center inline-flex items-center justify-center">
                  💗 Sweet
                </button>
              </div>
              <a href="https://www.wizardofswipe.com" target="_blank">
                <div className="w-full flex flex-col md:flex-row p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center">
                  <div className="flex flex-col items-start justify-between">
                    <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                      Want to get more matches on Tinder?
                    </h5>
                    <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 text-center md:text-left">
                      Let the the AI from the Wizard of Swipe manage your Tinder for you.
                      <br />
                      Use the code RIZZLINES and get a special discount.
                    </p>
                  </div>
                  <button type="button" className="md:ml-10 text-white flex flex-row items-center bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-xl px-5 py-2.5 text-center">
                    <img src="/wizardofswipe-nobg.png" alt="logo" className="h-12 mr-4" />
                    Wizard of Swipe
                  </button>
                </div>
              </a>

              <h1 className="mt-20 text-3xl font-semibold">
                {
                  'What is Rizz?'
                }
              </h1>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                {
                  `Rizz actually comes from the word charisma, where in southern Baltimore they've started to shorten it, to "rizzma" (the noun replacing charisma) and to "rizz" (the action of showing charisma), through twitch live streamer Kai Cenat's editor, a resident of south Baltimore, he started putting rizz in the compilation thumbnails and the word was adopted all over the United States`
                }
              </p>
              <p className="mt-2 text-sm text-blueGray-400">
                Source: <a href="http://rizz.urbanup.com/17593274" target="_blank" rel="noopener noreferrer">Urban Dictionary</a>
              </p>
              <h1 className="mt-20 text-3xl font-semibold">
                {
                  'What is a rizz line?'
                }
              </h1>
              <p className="mt-4 mb-10 text-lg leading-relaxed text-blueGray-500">
                {
                  'A rizz line is a pick-up line that is so smooth, it\'s like butter. Use it to impress your crush or significant other. Or just to make someone smile.'
                }
              </p>
            </div>
          </div>
        </div>

      </main>
    </>
  );
}