import React from "react";
import { Routes, Route } from "react-router-dom";

// views without layouts

import Landing from "views/Landing.js";


function App() {

    return (
        <Routes>
            <Route path="/" element={<Landing />} />
        </Routes>
    );
}

export default App;